import _helper from "../helper";
import _ActionSet from "../ActionSet";
import _types from "../types";
import _types2 from "./types";
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Features = void 0;
var helper_1 = _helper;
var ActionSet_1 = _ActionSet;
var types_1 = _types;
var types_2 = _types2;
/**
 * A set of Actions for Updating, Requesting Features of AppBridge
 * @public
 */
var Features = /** @class */function (_super) {
  __extends(Features, _super);
  function Features(app, options) {
    return _super.call(this, app, types_1.Group.Features, types_1.Group.Features, options ? options.id : undefined) || this;
  }
  /**
   * @public
   */
  Features.prototype.dispatch = function (action, payload) {
    switch (action) {
      case types_2.Action.REQUEST:
        this.dispatchFeaturesAction(types_2.Action.REQUEST, payload);
        break;
    }
    return this;
  };
  /**
   * @internal
   */
  Features.prototype.dispatchFeaturesAction = function (type, payload) {
    this.app.dispatch(helper_1.actionWrapper({
      group: types_1.Group.Features,
      type: type,
      payload: __assign(__assign({}, payload || {}), {
        id: this.id
      })
    }));
  };
  return Features;
}(ActionSet_1.ActionSet);
exports.Features = Features;
export default exports;